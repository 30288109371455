import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const LogoWrap = styled.div`
  text-align: center;
  flex: 0 1 36px;
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  @media (max-width: 900px) and (orientation: landscape) {
    flex: 0 1 25px;
  }
`
const Logo = () => {
    return (
      <LogoWrap as={Link} to="/">
          <img src="../images/nettikasino-2025.webp" width="280" height="101" alt="Nettikasino 2025" title="Nettikasino 2025"/>
      </LogoWrap>
    )
}

export default Logo